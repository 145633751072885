
@media (max-width:499px) {
    .otp_input {
        margin-right: 10px !important;
    }
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
    .login_modal {
        max-width: 80%;
    }
}

@media screen and (min-width: 1023px) and (max-width: 1280px) {
    .login_modal {
        min-width: 40%;
    }
}

